import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useUpdate,
  useGetIdentity,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  useSelect,
  DateField,
  useForm,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Popconfirm,
  Switch,
  Typography,
  Card,
  Row,
  Col,
  Select,
  Input,
  Form,
  Avatar,
  QRCode,
  Image,
} from "antd";
import { IUser } from "types";
import ViewImage from "components/view_image";

export const LocationList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { tableProps, tableQueryResult, filters, setFilters, setCurrent } =
    useTable({
      syncWithLocation: true,
    });

  const { mutate } = useUpdate();
  const { data: user } = useGetIdentity<IUser>();
  const total = tableQueryResult.data?.total || 0;
  const maxAllow = user?.package?.maxLocation || 0;

  return (
    <List
      createButtonProps={{
        children: t("location.create_title"),
      }}
      title={
        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {t("location.title")}
          </Typography.Title>
          <Typography.Text>
            {maxAllow ? `${total}/${maxAllow}` : ""}
          </Typography.Text>
        </div>
      }
    >
      <FilterBlock
        filters={filters}
        setFilters={(e: any) => {
          setFilters(e, "replace");
          setCurrent(1);
        }}
      />
      <br />
      <Card
        title={
          <Space>
            <Typography.Text>{t("location.label_search")}:</Typography.Text>
            <Input.Search
              allowClear
              placeholder={t("location.search")}
              onSearch={(e) => {
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value: e || undefined,
                  },
                ]);
                setCurrent(1);
              }}
            />
          </Space>
        }
      >
        <Table
          {...tableProps}
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t("common.of")} ${total}`,
          }}
        >
          <Table.Column
            dataIndex="logo"
            title={t("location.image")}
            width={120}
            render={(v, r: any) =>
              v ? (
                <ViewImage value={v} height={32} width={32} />
              ) : (
                <Avatar size={32} shape="square">
                  {r.name[0]}
                </Avatar>
              )
            }
          />
          <Table.Column dataIndex="name" title={t("location.name")} />
          <Table.Column
            width={150}
            dataIndex={["userInCharge"]}
            title={t("location.user_in_charge")}
            sorter
            render={(value: any) => value?.fullName}
          />
          <Table.Column
            dataIndex={["createdTime"]}
            title={t("common.created_at")}
            width={120}
            sorter
            render={(value: any) => <DateField value={value} />}
          />
          <Table.Column
            width={150}
            dataIndex={["updatedTime"]}
            title={t("common.updated_at")}
            sorter
            render={(value: any) => <DateField value={value} />}
          />

          <Table.Column
            dataIndex="qrCode"
            width={120}
            title={t("location.qrcode")}
            render={(v, r: any) => (
              <Image
                width={32}
                height={32}
                src={
                  "https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=" +
                  v
                }
              />
            )}
          />

          <Table.Column
            width={100}
            title={t("common.action")}
            align="center"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <Popconfirm
                  title={t("common.confirm_change_state")}
                  description={t("common.desc_change_state")}
                  onConfirm={() => {
                    mutate({
                      id: record.id ?? "",
                      resource: "location",
                      values: {
                        id: record.id ?? "",
                        status:
                          record.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
                      },
                    });
                  }}
                  okText={t("common.confirm_ok")}
                  cancelText={t("common.confirm_close")}
                >
                  <Switch checked={record.status == "ACTIVE"} />
                </Popconfirm>

                <EditButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </Card>
    </List>
  );
};

const FilterBlock = ({ filters, setFilters }: any) => {
  const { formProps, form } = useForm({
    warnWhenUnsavedChanges: false,
  });

  const t = useTranslate();

  const { selectProps } = useSelect({
    resource: "user",
    optionLabel: "fullName",
    optionValue: "userId",
    filters: [
      {
        field: "status",
        operator: "eq",
        value: "ACTIVE",
      },
    ],
    pagination: {
      pageSize: 10000,
    },
  });
  return (
    <Card>
      <Form
        {...formProps}
        onValuesChange={(e) => {
          const d = form.getFieldsValue() as any;
          const f = [
            {
              field: "status",
              operator: "eq",
              value: d.status != "ALL" ? d.status : undefined,
            },
          ];
          if (d.userInCharge && d.userInCharge != "NO_USER") {
            f.push({
              field: "userInCharge",
              operator: "eq",
              value: d.userInCharge,
            });
          }
          if (d.userInCharge == "NO_USER") {
            f.push({
              field: "userInCharge",
              operator: "isnull",
              value: "",
            });
          }
          setFilters(f, "replace");
        }}
      >
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <Form.Item
              style={{ margin: 0 }}
              name="status"
              label={t("filter.status")}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder={t("filter.status_placeholder")}
                options={[
                  { value: "ALL", label: t("common.all") },
                  { value: "ACTIVE", label: t("common.actived") },
                  { value: "INACTIVE", label: t("common.inactived") },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              style={{ margin: 0 }}
              name="userInCharge"
              label={t("location.user_in_charge")}
            >
              <Select
                allowClear
                {...selectProps}
                placeholder={t("location.select_user_in_charge")}
                options={[
                  {
                    value: "NO_USER",
                    label: t("location.no_user_in_charge"),
                  },
                  ...(selectProps.options || []),
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
