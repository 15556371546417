import React, { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useGetIdentity,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Row, Col, InputNumber, Select } from "antd";
import UploadFile from "components/image";
import MapInput from "components/map";
import { IUser } from "types";
import axios from "axios";
import _ from "lodash";
const api_key = "HjSQHVJJqNDgexVJQLq89MEg7hM8jWV1EpMfrZMc";

export const LocationCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult, form } = useForm({
    onMutationError(res) {
      const errors = _.get(res, "response.data.messages") || [];
      if (errors.length) {
        const fields = errors.map((e: any) => ({
          name: e.key,
          errors: [e.msg],
        }));
        form.setFields(fields);
        form.scrollToField(fields[0].name, {
          behavior: "smooth",
          block: "center",
        });
      }
    },
  });
  const { data: user } = useGetIdentity<IUser>();
  const address = Form.useWatch("address", form);
  const location = Form.useWatch("location", formProps.form);

  React.useEffect(() => {
    if (user?.companyCode) {
      formProps.form?.setFieldValue("companyCode", user?.companyCode);
    }
  }, [user?.companyCode]);

  useEffect(() => {
    if (location) {
      const [lat, long] = location.split("||");
      formProps.form?.setFieldValue("lat", lat);
      formProps.form?.setFieldValue("long", long);
    }
  }, [location]);

  const handleGeocode = async (address = "") => {
    const r = await axios.get(
      `https://rsapi.goong.io/Place/AutoComplete?input=${address}&api_key=${api_key}`
    );
    const place_id = _.get(r, "data.predictions[0].place_id");
    if (place_id) {
      const r2 = await axios.get(
        `https://rsapi.goong.io/Place/Detail?place_id=${place_id}&api_key=${api_key}`
      );

      formProps.form?.setFieldValue(
        "location",
        _.get(r2, "data.result.geometry.location.lat") +
          "||" +
          _.get(r2, "data.result.geometry.location.lng")
      );
    }
  };

  const { selectProps } = useSelect({
    resource: "user",
    optionLabel: "fullName",
    optionValue: "userId",
    filters: [
      {
        field: "status",
        value: "ACTIVE",
        operator: "eq",
      },
    ],
    pagination: {
      pageSize: 10000,
    },
  });
  const isEdit = formProps.initialValues?.id;
  return (
    <Create
      saveButtonProps={{
        ...saveButtonProps,
        children: isEdit ? t("Lưu") : t("location.create"),
      }}
      breadcrumb={null}
      footerButtonProps={{
        style: { float: "left", paddingLeft: "calc(25% + 12px)" },
      }}
      title={isEdit ? t("location.edit_title") : t("location.create_title")}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
          location:
            formProps.initialValues?.lat && formProps.initialValues?.long
              ? formProps.initialValues?.lat +
                "||" +
                formProps.initialValues?.long
              : "10.7017987||106.6495027",
          userInCharge: formProps.initialValues?.userInCharge?.userId || '',
        }}
        onFinish={(e: any) => {
          e.userInCharge = e.userInCharge || null;
          if (formProps.onFinish) {
            formProps.onFinish(e);
          }
        }}
      >
        <Form.Item name={["id"]} hidden>
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={"Status"}
          name={["status"]}
          initialValue="ACTIVE"
          hidden
        >
          <Input disabled />
        </Form.Item>

        <Row gutter={[12, 12]}>
          <Col span={6}></Col>
          <Col span={12}>
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <Form.Item
                  label={t("location.name")}
                  name={["name"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <Input
                    maxLength={200}
                    placeholder={t("location.enter_name")}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={t("location.address")}
                  name={["address"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    rows={3}
                    maxLength={200}
                    onBlur={() => {
                      handleGeocode(address);
                    }}
                    placeholder={t("location.address_enter")}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={["location"]}
                  required
                  rules={[{ required: true }]}
                >
                  <MapInput />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={t("location.note")} name={["description"]}>
                  <Input.TextArea
                    rows={5}
                    showCount
                    maxLength={200}
                    placeholder={t("location.note_enter")}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label={t("location.image")} name={["logo"]}>
                  <UploadFile />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item
                  label={t("location.user_in_charge")}
                  name={["userInCharge"]}
                >
                  <Select
                    {...selectProps}
                    placeholder={t("location.select_user_in_charge")}
                    // allowClear
                    options={[
                      {
                        value: '',
                        label: t("location.no_user_in_charge"),
                      },
                      ...selectProps.options || [],
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col span={12} hidden>
                <Form.Item label="lat" name={["lat"]}>
                  <InputNumber disabled style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={12} hidden>
                <Form.Item label="long" name={["long"]}>
                  <InputNumber disabled style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
