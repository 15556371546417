import React from "react";
import {
  IResourceComponentsProps,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import { useTable, List, DateField, useSelect, useForm } from "@refinedev/antd";
import {
  Table,
  Space,
  Popconfirm,
  Switch,
  Tag,
  Typography,
  Card,
  Form,
  Row,
  Col,
  Select,
  Input,
} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import Link from "antd/es/typography/Link";

export const AssignList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { tableProps, setFilters, filters, setCurrent } = useTable({
    syncWithLocation: true,
  });

  const { mutate } = useUpdate();

  return (
    <List
      title={t("assign.title")}
      createButtonProps={{ children: t("assign.create_new") }}
    >
      <FilterBlock
        filters={filters}
        setFilters={(e: any) => {
          setFilters(e, "replace");
          setCurrent(1);
        }}
      />
      <br />
      <Card
        title={
          <Space>
            <Typography.Text>{t("assign.label_search")}:</Typography.Text>
            <Input.Search
              allowClear
              placeholder={t("assign.search")}
              onSearch={(e) => {
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value: e || undefined,
                  },
                ]);
                setCurrent(1);
              }}
            />
          </Space>
        }
      >
        <Table
          {...tableProps}
          rowKey="id"
          scroll={{ x: 1500 }}
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t("common.of")} ${total}`,
          }}
        >
          <Table.Column
            dataIndex="assignCode"
            width={100}
            title={t("assign.code")}
            render={(v, r: any) => (
              <Link href={"/assign/show/" + r.id}>{v}</Link>
            )}
          />
          <Table.Column
            dataIndex="name"
            width={200}
            title={t("assign.assign")}
            render={(v, r: any) => (
              <Link href={"/assign/show/" + r.id}>{v}</Link>
            )}
          />
          <Table.Column
            dataIndex={["templates", "name"]}
            width={200}
            title={t("assign.template")}
          />
          <Table.Column
            dataIndex={["location", "name"]}
            width={200}
            title={t("assign.location")}
          />
          <Table.Column
            dataIndex="checkListType"
            width={120}
            title={t("assign.type")}
            render={(r) => {
              if (r == "DAILY") return t("common.assign_type_DAILY");
              if (r == "INCURRED") return t("common.assign_type_INCURRED");
              return "";
            }}
          />

          <Table.Column
            dataIndex={["startTime"]}
            title={t("assign.time")}
            width={250}
            render={(value: any, r: any) => (
              <Space direction="vertical" size="small" align="center">
                <Space>
                  <DateField
                    format="DD/MM/YYYY"
                    value={r.startTime.slice(0, 10)}
                  />
                  <ArrowRightOutlined />
                  <DateField
                    format="DD/MM/YYYY"
                    value={r.endTime.slice(0, 10)}
                  />
                </Space>
                <Space>
                  <Typography.Text style={{ fontSize: 12 }}>
                    {r.timeHourStart}
                  </Typography.Text>
                  <ArrowRightOutlined />
                  <Typography.Text style={{ fontSize: 12 }}>
                    {r.timeHourEnd}
                  </Typography.Text>
                </Space>
              </Space>
            )}
          />
          <Table.Column
            dataIndex={["maxWork"]}
            width={120}
            align="right"
            title={t("assign.kpi")}
          />

          <Table.Column
            dataIndex={["createdTime"]}
            title={t("common.created_at")}
            render={(value: any) => <DateField value={value} />}
            width={120}
            sorter
          />

          <Table.Column
            width={100}
            title={t("common.action")}
            fixed="right"
            align="center"
            dataIndex="actions"
            render={(_, record: any) => (
              <Space>
                <Popconfirm
                  title={t("common.confirm_change_state")}
                  description={t("common.desc_change_state")}
                  onConfirm={() => {
                    mutate({
                      id: record.id ?? "",
                      resource: "assign",
                      values: {
                        id: record.id ?? "",
                        status:
                          record.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
                      },
                    });
                  }}
                  okText={t("common.confirm_ok")}
                  cancelText={t("common.confirm_close")}
                >
                  <Switch checked={record.status == "ACTIVE"} />
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
      </Card>
    </List>
  );
};

const FilterBlock = ({ filters, setFilters }: any) => {
  const { formProps, form } = useForm({
    warnWhenUnsavedChanges: false,
  });

  const { selectProps: tempateSelectProps } = useSelect({
    resource: "template",
    optionLabel: "name",
    filters: [
      {
        field: "status",
        operator: "eq",
        value: "ACTIVE",
      },
    ],
  });

  const { selectProps: locationSelectProps } = useSelect({
    resource: "location",
    pagination: { pageSize: 10000 },
    optionLabel: "name",
    filters: [
      {
        field: "status",
        operator: "eq",
        value: "ACTIVE",
      },
    ],
  });

  const t = useTranslate();

  return (
    <Card>
      <Form
        {...formProps}
        onValuesChange={(e) => {
          const d = form.getFieldsValue() as any;
          setFilters(
            [
              {
                field: "status",
                operator: "eq",
                value: d.status != "ALL" ? d.status : undefined,
              },
              {
                field: "templateId",
                operator: "eq",
                value: d.templateId,
              },
              {
                field: "locationId",
                operator: "eq",
                value: d.locationId,
              },
              {
                field: "checkListType",
                operator: "eq",
                value: d.checkListType != "ALL" ? d.checkListType : undefined,
              },
            ],
            "replace"
          );
        }}
      >
        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item
              style={{ margin: 0 }}
              name="templateId"
              label={t("assign.template")}
            >
              <Select
                {...tempateSelectProps}
                style={{ width: "100%" }}
                placeholder={t("assign.select_template")}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              style={{ margin: 0 }}
              name="locationId"
              label={t("assign.location")}
            >
              <Select
                {...locationSelectProps}
                style={{ width: "100%" }}
                placeholder={t("assign.select_location")}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              style={{ margin: 0 }}
              name="status"
              label={t("filter.status")}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder={t("filter.status_placeholder")}
                options={[
                  { value: "ALL", label: t("common.all") },
                  { value: "ACTIVE", label: t("common.actived") },
                  { value: "INACTIVE", label: t("common.inactived") },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              style={{ margin: 0 }}
              name="checkListType"
              label={t("assign.type")}
            >
              <Select
                style={{ width: "100%" }}
                placeholder={t("assign.select_type")}
                options={[
                  { value: "ALL", label: t("common.all") },
                  { value: "DAILY", label: t("common.assign_type_DAILY") },
                  {
                    value: "INCURRED",
                    label: t("common.assign_type_INCURRED"),
                  },
                ]}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
